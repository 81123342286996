export default `<!-- Download Canny SDK -->
<script>!function(w,d,i,s){function l(){if(!d.getElementById(i)){var f=d.getElementsByTagName(s)[0],e=d.createElement(s);e.type="text/javascript",e.async=!0,e.src="https://canny.io/sdk.js",f.parentNode.insertBefore(e,f)}}if("function"!=typeof w.Canny){var c=function(){c.q.push(arguments)};c.q=[],w.Canny=c,"complete"===d.readyState?l():w.attachEvent?w.attachEvent("onload",l):w.addEventListener("load",l,!1)}}(window,document,"canny-jssdk","script");</script>

<!-- Use the Canny SDK to identify the current user of your website -->
<script>
Canny('identify', {
  appID: 'YOUR_APP_ID',
  user: {
    // Replace these values with the current user's data
    email: user.email,
    name: user.name,
    id: user.id,

    // These fields are optional, but recommended:
    avatarURL: user.avatarURL,
    created: new Date(user.created).toISOString(),
  },
});
</script>`;
