import React, { Component } from 'react';

import Helmet from './Helmet';

const Title = 'Install Docs | Canny';
const Description =
  "Install our JavaScript SDK to identify your users. This way, when they leave feedback on Canny, it'll be tied to their existing user account in your application.";
const Image = 'https://canny.io/images/canny-social.png';

export default class InstallDocsHelmet extends Component {
  render() {
    return (
      <Helmet
        title={Title}
        meta={[
          { name: 'description', content: Description },

          { property: 'og:image', content: Image },
          { property: 'og:title', content: Title },
          { property: 'og:description', content: Description },
          { property: 'og:url', content: 'https://canny.io/docs/install' },
          { property: 'og:type', content: 'website' },

          { name: 'twitter:card', content: 'summary_large_image' },
          { name: 'twitter:site', content: '@cannyHQ' },
          { name: 'twitter:title', content: Title },
          { name: 'twitter:description', content: Description },
          { name: 'twitter:image', content: Image },
        ]}
      />
    );
  }
}
