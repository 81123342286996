import React, { Component } from 'react';

import PropTypes from 'prop-types';

import { CompanyContext } from 'common/containers/CompanyContainer';
import { ViewerContext } from 'common/containers/ViewerContainer';
import IdentifyEmbedSnippet from 'common/developers/docs/sdk/snippets/IdentifyEmbedSnippet';
import MultiLanguageCodeBlock from 'common/developers/docs/widget/MultiLanguageCodeBlock';
import InstallDocsHelmet from 'common/helmets/InstallDocsHelmet';
import LazyLoadedImage from 'common/LazyLoadedImage';
import { P } from 'common/ui/Text';
import UppercaseHeader from 'common/UppercaseHeader';
import devURL from 'common/util/devURL';
import withContexts from 'common/util/withContexts';

import OpteoExample from 'img/opteo-example.gif';

import 'css/components/developers/docs/install/_InstallDocs.scss';

class InstallDocs extends Component {
  static propTypes = {
    company: PropTypes.object,
    viewer: PropTypes.object,
  };

  renderCustomDomainTip() {
    const { company } = this.props;
    if (!company || company.loading || company.domains[0]) {
      return null;
    }

    const settingsURL = devURL(
      'https://' + company.subdomain + '.canny.io/admin/settings/custom-domain'
    );
    return (
      <div className="tip">
        <UppercaseHeader>Tip</UppercaseHeader>
        <div className="text">
          Want to use feedback.yourdomain.com instead?&nbsp;
          <a href={settingsURL} rel="noopener" target="_blank">
            Set up a custom&nbsp;domain
          </a>
        </div>
      </div>
    );
  }

  renderEasySetup() {
    const { company } = this.props;
    if (!company?._id) {
      return null;
    }

    const inviteURL = devURL('https://' + company.subdomain + '.canny.io/admin/settings/team');
    return (
      <div className="easySetup">
        <span className="icon-bolt" />
        <div className="main">
          Setup is easy! It typically takes an engineer less than 15&nbsp;minutes. Need a teammate
          to set this up?&nbsp;
          <a className="invite" href={inviteURL} rel="noopener" target="_blank">
            Send them an&nbsp;invite
          </a>
        </div>
      </div>
    );
  }

  renderLink() {
    const { company } = this.props;
    if (!company || company.loading) {
      return 'yourcompany.canny.io';
    }

    const { domains, subdomain } = company;
    return domains[0] ? domains[0] : subdomain + '.canny.io';
  }

  renderPrompt() {
    const { company, viewer } = this.props;
    if (company?._id) {
      return null;
    }

    if (!viewer || viewer.loggedOut) {
      return (
        <P className="description">
          <span className="bold">To find your appID</span>, log in above. You'll see your appID
          pre&#8209;filled in the code&nbsp;snippet.
        </P>
      );
    }
  }

  renderIdentifySnippet() {
    const { company } = this.props;
    if (!company?._id) {
      return IdentifyEmbedSnippet;
    }

    return IdentifyEmbedSnippet.replace('YOUR_APP_ID', company._id);
  }

  renderLinkSnippet() {
    const { company } = this.props;
    return `<a data-canny-link href="https://${
      company.subdomain || 'your-company'
    }.canny.io">\n  Give feedback\n</a>`;
  }

  render() {
    return (
      <section className="installDocs">
        <InstallDocsHelmet />
        <div className="heading">Install Canny</div>
        {this.renderEasySetup()}
        <div className="stepHeading">1. Link to Canny in your product</div>
        <P className="description">
          In your application, add a link to your Canny feedback site. Put the link somewhere it's
          easy to&nbsp;find.
        </P>
        <MultiLanguageCodeBlock
          blocks={[
            {
              code: this.renderLinkSnippet(),
              language: 'html',
            },
          ]}
          showSelector={false}
        />
        <P className="description">
          Note that the <span className="code">data-canny-link</span> attribute is very important,
          otherwise user authentication will not work.
        </P>
        {this.renderCustomDomainTip()}
        <LazyLoadedImage src={OpteoExample} />
        <div className="stepHeading">2. Identify Users</div>
        <P className="description">
          Install our JavaScript SDK to identify your users. This way, when they leave feedback on
          Canny, it'll be tied to their existing user account in your application.
        </P>
        <P className="description">
          Use the following code in your application wherever the user is logged in, and might click
          on a link to Canny. You'll have to modify it to pass in the current user's data.
        </P>
        {this.renderPrompt()}
        <MultiLanguageCodeBlock
          blocks={[
            {
              code: this.renderIdentifySnippet(),
              language: 'html',
            },
          ]}
          showSelector={false}
        />
        <P className="description">
          Note that Canny Identify won't identify admins. When you're testing, use a non-admin
          account.
        </P>
        <P className="description">
          This step is optional, but strongly recommended. It provides a better experience for your
          users, and more valuable context for your team.
        </P>
      </section>
    );
  }
}

export default withContexts({
  company: CompanyContext,
  viewer: ViewerContext,
})(InstallDocs);
