import React, { useContext, useEffect, useRef } from 'react';

import { HighlightContext } from 'common/containers/HighlightContainer';

// if you need to render a new language, please add here
// also make sure that language is highlighted properly. See implementation for go language on HighlightContainer

const SupportedHighlightLanguages = {
  json: 'json',
  http: 'http',
  shell: 'shell',
  js: 'js',
  csharp: 'csharp',
  go: 'go',
  java: 'java',
  php: 'php',
  python: 'python',
  ruby: 'ruby',
  css: 'css',
  bash: 'bash',
  xml: 'xml',
} as const;

type Props = {
  children: string;
  className?: string;
  lang: (typeof SupportedHighlightLanguages)[keyof typeof SupportedHighlightLanguages];
};

const Highlight = ({ lang = SupportedHighlightLanguages.json, className, children }: Props) => {
  const highlight = useContext(HighlightContext);
  const codeBlockRef = useRef<HTMLElement>(null);

  useEffect(() => {
    if (highlight && codeBlockRef.current) {
      highlight(codeBlockRef.current);
    }
  }, [lang, highlight, children]);

  return (
    <pre className={className}>
      <code className={`language-${lang}`} ref={codeBlockRef}>
        {children}
      </code>
    </pre>
  );
};
export default Highlight;
