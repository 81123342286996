import React, { Component } from 'react';

import classnames from 'classnames';
import PropTypes from 'prop-types';

import Highlight from 'common/developers/docs/api/Highlight';
import Tappable from 'common/Tappable';

import 'css/components/developers/docs/widget/_MultiLanguageCodeBlock.scss';

export default class MultiLanguageCodeBlock extends Component {
  static propTypes = {
    blocks: PropTypes.arrayOf(
      PropTypes.shape({
        code: PropTypes.string,
        language: PropTypes.string,
        name: PropTypes.string,
      })
    ).isRequired,
    colorScheme: PropTypes.oneOf(['light', 'dark']),
    showSelector: PropTypes.bool,
  };

  static defaultProps = {
    colorScheme: 'light',
    defaultSelectedIndex: 0,
    showSelector: true,
  };

  state = {
    selectedIndex: this.props.defaultSelectedIndex,
  };

  onLanguageTapped = (indexTapped) => {
    this.setState({
      selectedIndex: indexTapped,
    });
  };

  renderSelector() {
    if (!this.props.showSelector) {
      return null;
    }

    const languages = [];
    this.props.blocks.forEach((block, i) => {
      var className = 'language';
      if (this.state.selectedIndex === i) {
        className += ' selected';
      }

      const name = block.name;
      languages.push(
        <Tappable key={name} onTap={this.onLanguageTapped.bind(this, i)}>
          <div className={className}>{name}</div>
        </Tappable>
      );
    });

    return <div className={classnames('selector', this.props.colorScheme)}>{languages}</div>;
  }

  renderCodeBlock(codeBlock) {
    const showSelector = this.props.showSelector;
    const selectedLanguage = codeBlock.language;
    return (
      <Highlight className={showSelector ? '' : 'noSelector'} lang={selectedLanguage}>
        {codeBlock.code}
      </Highlight>
    );
  }

  renderFiles(files) {
    return (
      <div className={'files'}>
        {files.map((file, i) => (
          <div className={`file file${i}`} key={i}>
            <div className={'header'}>{file.name}</div>
            {this.renderCodeBlock(file)}
          </div>
        ))}
      </div>
    );
  }

  renderCode() {
    const selectedBlock = this.props.blocks[this.state.selectedIndex];

    if (selectedBlock.files) {
      return this.renderFiles(selectedBlock.files);
    }

    return this.renderCodeBlock(selectedBlock);
  }

  render() {
    return (
      <div className="multiLanguageCodeBlock">
        {this.renderSelector()}
        {this.renderCode()}
      </div>
    );
  }
}
