import React, { Component } from 'react';

import PropTypes from 'prop-types';

import loadScript from 'common/util/loadScript';
import withContexts from 'common/util/withContexts';

import { ThemeContext } from './ThemeContainer';

export const HighlightContext = React.createContext();

const GoScriptID = 'highlightjs-go-script';
const ScriptID = 'highlightjs-script';
const StylesID = 'highlightjs-styles';

const ScriptURL = 'https://cdnjs.cloudflare.com/ajax/libs/highlight.js/9.11.0/highlight.min.js';
const GoScriptURL =
  'https://cdnjs.cloudflare.com/ajax/libs/highlight.js/9.11.0/languages/go.min.js';
const LightStylesURL =
  'https://cdnjs.cloudflare.com/ajax/libs/highlight.js/9.11.0/styles/atom-one-light.min.css';
const DarkStylesURL =
  'https://cdnjs.cloudflare.com/ajax/libs/highlight.js/9.11.0/styles/atom-one-dark.min.css';

class HighlightContainer extends Component {
  static propTypes = {
    themeContext: PropTypes.shape({
      theme: PropTypes.string,
    }),
  };

  constructor(props, context) {
    super(props, context);

    this._elementsToHighlight = [];
  }

  componentDidMount() {
    this.loadScripts();
    this.loadStyles();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.themeContext?.theme === this.props.themeContext?.theme) {
      return;
    }

    this.loadScripts();
    this.loadStyles();
  }

  async loadScripts() {
    loadScript(ScriptURL, ScriptID, {
      callback: () => {
        loadScript(GoScriptURL, GoScriptID, {
          callback: this.onScriptLoaded,
        });
      },
    });
  }

  loadStyles() {
    const highlightStyles = document.getElementById(StylesID);
    if (highlightStyles) {
      highlightStyles.remove();
    }

    const theme = this.props.themeContext?.theme;
    const stylesURL = theme === 'dark' ? DarkStylesURL : LightStylesURL;

    const styles = document.createElement('link');
    styles.className = styles.href = stylesURL;
    styles.id = StylesID;
    styles.rel = 'stylesheet';

    document.body.appendChild(styles);
  }

  highlight = (element) => {
    if (typeof window.hljs === 'undefined') {
      this._elementsToHighlight.push(element);
      return;
    }

    window.hljs.highlightBlock(element);
  };

  onScriptLoaded = () => {
    this._elementsToHighlight.forEach(this.highlight);
    this._elementsToHighlight = [];
  };

  render() {
    return (
      <HighlightContext.Provider value={this.highlight}>
        {this.props.children}
      </HighlightContext.Provider>
    );
  }
}

export default withContexts({
  themeContext: ThemeContext,
})(HighlightContainer);
